$dotted-line-color: #958576;
$solid-line-color: #7a6f6a;

.ImageFocusAnimation-container {
  margin: 10px auto;
  padding: 3px 0;
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-top: 1px dotted $dotted-line-color;
  border-bottom: 1px dotted $dotted-line-color;
}

.ImageFocusAnimation {
  margin: 10px auto;
  max-width: 90%;
}

.ImageFocusAnimation-container p {
  color: #000;
  max-width: 80%;
  margin: 10px auto;
  font-size: 1.2em;
}

.ImageFocusAnimation-container h2 {
  color: #000;
  max-width: 80%;
  margin: 10px auto;
  font-size: 2.2em;
}

.ImageFocusAnimation-container .septuple-container {
  width: 120%;
  padding: 12px 20px 14px;
  display: flex;
  margin-left: -10%;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  border-top: 1px solid $solid-line-color;
  border-bottom: 1px solid $solid-line-color;
  min-height: 100px;
}


.ImageFocusAnimation-container .ImageFocusAnimation-image-container {
  box-shadow: inset 0 0 25px 6px #fff;
  filter: drop-shadow(0 6px 2px rgba(16, 16, 16, 0.5));
  border-radius: 50%;
  margin: 0 -10px;
  padding: 0;
  border: 0;
  width: 400px;
  max-width: 20%;
  overflow: hidden;
  min-height: 100%;
}

.ImageFocusAnimation-image-container .droplet-overlay {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset -7px 8px 10px 7px rgba(0, 0, 0, 0.5),
              inset 5px -12px 25px 5px rgba(255, 255, 255, 0.75);
}

.ImageFocusAnimation-image-container .light-dot {
  position: absolute;
  display: inline-block;
  top: 12%;
  right: 30%;
  width: 13%;
  height: 20%;
  border-radius: 50%;
  transform: rotate(-62deg);
  background-color: rgba(255, 255, 255, 0.35);
}

.medium-light-overlay {
  background-color: rgba(60, 60, 60, 0.45);
}

.light-overlay {
  background-color: rgba(60, 60, 60, 0.35);
}

.lighter-overlay {
  background-color: rgba(60, 60, 60, 0.25);
}

.medium-light-blur {
  filter: blur(2px) grayscale(85%);
}

.light-blur {
  filter: blur(1.5px) grayscale(65%);
}
.lighter-blur {
  filter: blur(1px) sepia(38%);
}


.ImageFocusAnimation-image-container .ImageFocusAnimation-image {
  margin: 0;
  padding: 0;
  border: 0;
  width: 400px;
  max-width: 100%;
  height: auto;
}


.ImageFocusAnimation-image-container .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  //box-shadow: inset 0 1px 25px 9px #eee;
}

.ImageFocusAnimation-container .first-animated {
  z-index: 1;
}

.ImageFocusAnimation-container .first-animated .light-dot{
  background-color: rgba(255, 255, 255, 0.1);
}


.ImageFocusAnimation-container .second-animated {
  z-index: 2;
  transition-delay: 0.5s;
}

.ImageFocusAnimation-container .second-animated .light-dot{
  background-color: rgba(255, 255, 255, 0.15);
}


.ImageFocusAnimation-container .third-animated {
  z-index: 3;
  transition-delay: 1s;
}

.ImageFocusAnimation-container .third-animated .light-dot{
  background-color: rgba(255, 255, 255, 0.22);
}

.ImageFocusAnimation-container .fourth-animated {
  z-index: 4;
  transition-delay: 1.5s;
}

.ImageFocusAnimation-container .hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  transform: scale(0.7) rotate(-30deg);
}

.ImageFocusAnimation-container.hidden {

}

.shown {
  visibility: visible;
  opacity: 1;
  transition: all 2s ease;
  transform: scale(0.8);
}

//Causes the second set of images to grow more
.ImageFocusAnimation-container .second-animated.shown {
  transform: scale(1.0);
}

//Causes the third set of images to grow even more
.ImageFocusAnimation-container .third-animated.shown {
  transform: scale(1.2);
}

//Causes the center image to grow most
.ImageFocusAnimation-container .fourth-animated.shown {
  transform: scale(1.45);
}


.ImageFocusAnimation-container .circle {
  border-radius: 50%;
  margin-bottom: -3px;
}

.ImageFocusAnimation-container p strong,
.ImageFocusAnimation-container p em{
  font-size: 1.05em;
}

.ImageFocusAnimation-container p em{
  text-decoration: underline;
}
