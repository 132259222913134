html, body, #root, .App {
  background-color: #d5cdb9;
  background: linear-gradient(-180deg, #d5cdb9, 80%, black);
  min-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.blurred {
  filter: blur(3px);
}
