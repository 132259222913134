.ShrinkingHeader {
  position: relative;
  top: 0;
  height: 100%;
}

.nav-container {
  width: 100%;

  overflow: hidden;
  position: static;
  top: 0;

  padding-bottom: 10px;
}

.pseudo-background {
  position: absolute;
  bottom: 0;
  left: 0;
  height: auto;
  min-height: 100%;
  z-index: 1;
}

.pseudo-background-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0,0,0,0) 60%, rgba(0,0,0,0), #d5cdb9 94%, #d5cdb9);
  z-index: 2;
}

.Header-container {
  margin: 0 auto;
  top: 0;
  z-index: 3;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  max-height: 100%;
}

.Header-container a{
  text-decoration: none;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 350px;
  padding: 0 5px;
  box-sizing: border-box;
  min-width: 115%;
  position: relative;
  left: -6%
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}

.zero-height {
  height: 0px;
  border: 0;
  padding: 0;
  z-index: 0;
  //left: -7.5%;
}

.full-height {
  height: 80px;
  padding: 5px 5px 5px 0;
}

.shown {
  visibility: visible;
  opacity: 1;
  transition: all 2s ease;
}

.logo {
  height: 0;

  transition: all 0.5s ease;
}

Header .small-logo:hover, Header .small-logo:focus,
Header .small-title:hover, Header .small-title:focus,
Header .hamburger-button:hover, Header .hamburger-button:focus{
  filter: drop-shadow(0 0 5px #fff);
  transform: scale(1.05);
}

.Header-image {
  height: 165px;
  transition: all 0.5s ease;
  box-shadow: 0 0 5px #fff;
}

.title-container Navlink,
.title-container a {
  line-height: 1.05em;
  text-decoration: underline;
  text-decoration-color: rgba(50,50,50,1);
  color: #222;
  filter: drop-shadow(-2px 7px 5px #333);
  text-shadow: 0 0 5px #fff;
}

.Header Navlink.round,
.Header a.round {
  display: inline-block;
  transition: all 0.5s ease;
  z-index: 7;
  margin-top: 10px;
  margin-left: 20px;
  border-radius: 50%;

}

.Header Navlink.round img,
.Header a.round img{
  box-sizing: border-box;
  margin-bottom: -3px;
  border-radius: 50%;
  box-shadow: 0 0 7px #fff;
  overflow: hidden;
  //transform: scale(1);
  //transform: translate3d(0px,0px,0px), scale(1);
}

logo:hover, logo:focus,
Header Navlink.round:hover img, Header a.round:hover img,
Header Navlink.round:focus img, Header a.round:focus img {
  transform: scale(1.05);
  box-shadow: 0 0 20px #fff;
}

.nav-menu {
  flex-direction: column;
  //Caused issues on the smallest screens
  //width: 200px;
  justify-content: center;
  border: 2px solid #000;
  margin: 0;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  z-index: 9;
  transition: all 1s ease;
  overflow: visible;
}

.unblur {
  filter: blur(0px);
}

.nav-menu p,
.nav-menu a,
.nav-menu NavLink {
  color: #000;
  text-decoration: underline;
  text-align: center;
  padding: 10px 13px;
  width: 174px;
  font: 600 1.6em "Barlow Condensed", sans-serif;
  line-height: 1.5em;
}

.hide-nav {
  right: -200px;
  opacity: 0;
  visibility: hidden;
}

.show-nav {
  right: 5px;
  opacity: 1;
  visibility: visible;
}

.title-container {
  color: #000;
  text-align: center;
  margin: 10px 15px;
}

.page-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 30, 0.7);
}

.hide-overlay {
  z-index: -1;
  opacity: 0;
  display: none;
}

.show-overlay {
  z-index: 8;
  opacity: 1;
  display: flex;
}

h1 {
  width: 100%;
  text-align: right;
  padding: 10px 0 5px;
  margin: 0;
  font: 300 2.5em "Josefin Sans", sans-serif;
}

.carbon-container {
  border-radius: 10px;
  background-color: #fff;
  background-size: 20px 20px;
  box-shadow: 2px 2px 10px #000, inset -3px -3px 15px #444;
  box-sizing: border-box;
  margin: 20px 10px;
}

/* Media Query-related code */

Header .small-logo {
  min-width: 68px;
  //max-width: 100px;
  height: 68px;
  width: 68px;
  box-sizing: border-box;
  border-radius: 50%;
  opacity: 1;
  transition: all 0.5s ease;
  filter: drop-shadow(0 0 2px #fff);
}

Header .small-title {
  height: auto;
  max-height: 80px;
  max-width: 200px;
  filter: drop-shadow(0 0 3px #fff);
  transition: all 0.5s ease;
  //margin: 0 auto auto 20px;
}

Header .hamburger-button {
  display: flex;
  max-width: 20%;
  height: 54px;
  width: 54px;
  border: 5px solid #000;
  margin: 0;
  transition: all 0.5s ease;
  border-radius: 10em;
  filter: drop-shadow(0 0 2px #fff);
}

Header .small-title a img {

}

@media screen and (min-width: 351px) {
  .hamburger-button {
    border: 3px solid #000;
  }
}

@media screen and (min-width: 451px) {
  .hamburger-button {
    border: 4px solid #000;
  }
  Header .small-title {
    //margin-left: 20px;
  }
}

@media screen and (min-width: 501px) {
  .hamburger-button {
    border: 5px solid #000;
  }

}


@media screen and (min-width: 601px) {
  Header .small-logo {
    min-width: 74px;
    height: 74px;
    width: 74px;
    box-sizing: border-box;
    position: relative;
  }
  .Header {
    justify-content: space-between;
    left: -7.5%
  }

  .Header-container {
    margin-top: -10px;
  }

  .pseudo-background {

  }

  .pseudo-background-overlay {
    background-image: linear-gradient(180deg, rgba(0,0,0,0) 60%, rgba(0,0,0,0), #d5cdb9 97%, #d5cdb9);
    background-position: center 80%;
    //background-size: auto 140%;
    background-repeat: no-repeat;
  }

  .nav-container {

  }
  .logo {
    height: 240px;
    transition: all 0.5s ease;
  }

  Header .NavMenu-container {
    flex-direction: row;
    justify-content: space-between;
    border: none;
    margin: 5px 15px 5px 0;
    padding: 5;
    position: static;
  }

  .title-container nav,
  .StickyHeader nav {
    flex-direction: row;
    justify-content: space-between;
    border: none;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    margin: 5px auto;
    position: static;
    width: 100%;
    color: #000;
  }

  .title-container Navlink,
  .title-container a {
    font-size: 1.05em;
    margin: 10px 0;
    display: inline-block;
    font: 600 1em "Barlow Condensed", sans-serif;
    line-height: 1.05em;
    width: auto;
    border: none;
  }
  //Fix thanks to 350D
  .title-container Navlink::before,
  .title-container a::before {
    display: block;
    font-size: 1.05em;
    //margin: 10px 0;
    font: 600 1em "Barlow Condensed", sans-serif;
    line-height: 1.05em;
    content: attr(title);
    height: 0;
    overflow: hidden;
    visibility: hidden;

  }

}

@media screen and (min-width: 633px) {
  .title-container Navlink,
  .title-container a {
    margin: 10px 0;
  }
}

@media screen and (min-width: 675px) {
  .title-container Navlink,
  .title-container a {
    margin: 10px 0;
    font-size: 1em;
    line-height: 1em;
  }

}

@media screen and (min-width: 723px) {
  .logo {
    height: 280px;
    transition: all 0.5s ease;
  }

  .Header-image {
    height: 190px;
    transition: all 0.5s ease;
  }

  .title-container Navlink,
  .title-container a {
    margin: 10px 0;
    font-size: 1.05em;
    line-height: 1.05em;
  }
}

@media screen and (min-width: 795px) {
  .nav-container {
    background-size: auto 160%;
  }
  .Header {
    left: -8.5%;
  }
  .logo {
    height: 300px;
    transition: all 0.5s ease;
  }

  .Header-image {
    height: 220px;
    transition: all 0.5s ease;
  }
}
